import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import NotFound from "./components/404";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/:schema/:token" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
