import React from "react";
import notfound from "../assets/notfound.svg";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="not-found-wrapper">
      <div className="not-found-content">
        <img src={notfound} alt="not_found" className="not-found-img" />
        <Link to="/">
          <button className="not-found-btn">Take me to home</button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
